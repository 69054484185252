import { isNil, omitBy, sortBy } from "lodash-es";
import useHttp from "~/composables/useHttp";
import type { GetBannerAPIResponse } from "~/types/Banner";
export interface GetBannerOptions {
  endPointApi?: string;
  cityId?: string | number;
  tagIds?: string;
}

async function getBanners(options: GetBannerOptions): Promise<{
  isSuccess: boolean;
  data: null | GetBannerAPIResponse[];
  message: string;
}> {
  const defaultErrorMessage = "failed get promotion list";
  try {
    const params: { cityId?: string | number; restaurantTagIds?: string } = {
      cityId: options.cityId,
      restaurantTagIds: options.tagIds,
    };
    const parsedParams = omitBy(params, isNil);
    const url = options.endPointApi || "/banners.json";
    const { data, error } = await useHttp({
      url: `${url}`,
      method: "GET",
      params: parsedParams,
      canRetry: true,
    });
    const responseData = data as GetBannerAPIResponse[];
    const errorMessage = error.message || defaultErrorMessage;
    if (Array.isArray(responseData)) {
      return {
        isSuccess: true,
        data: sortBy(responseData, ["orderNumber"]) as GetBannerAPIResponse[],
        message: "",
      };
    } else {
      return {
        isSuccess: false,
        data: null,
        message: errorMessage,
      };
    }
  } catch (err: any) {
    return {
      isSuccess: false,
      data: null,
      message: "failed get promotion list",
    };
  }
}

export { getBanners };
